import StreamsGroup from "./StreamsGroup";

const StreamsMenu = ({data, lives, setLives, chat, setChat, setCardData, setCardVisibility, setCardPosition}) => {
    const hasLive = lives.length > 0

    return (<div className={[
        'grow flex transition-all',
        hasLive && 'content-start items-center overflow-y-visible overflow-x-scroll hide-scrollbar',
        !hasLive && 'flex-wrap place-content-center gap-3 p-10',
        'text-white text-center text-xs',
    ].join(' ')}>
        {data && data.lives && Object.keys(data.lives).map((key) => {
            return (<StreamsGroup key={'stream-group-' + key} organisations={data.organisations} organisation={key}
                                  streams={data.lives[key]} lives={lives} setLives={setLives} chat={chat}
                                  setChat={setChat} setCardData={setCardData} setCardVisibility={setCardVisibility} setCardPosition={setCardPosition}/>)
        })}
    </div>)
}

export default StreamsMenu
