// noinspection JSUnresolvedVariable

import {Component} from "react";

class Live extends Component {
    shouldComponentUpdate(nextProps, nextState) {
        return false;
    }

    render() {
        const muted = this.props.index === 0 ? 'false' : 'true'
        const url = `https://player.twitch.tv/?channel=${this.props.channel}&parent=${window.location.host}&muted=${muted}&autoplay=true`
        return (<iframe title="Twitch" src={url} className="w-full h-full"/>)
    }
}

export default Live
