import Live from "./Live";

const Lives = ({lives, layouts, layout}) => {
    let lives_index = lives.length || 1;

    if (layouts[lives_index] === undefined) {
        lives_index = 1
    }
    const layout_index = layout[lives_index] || 0;

    return (<div className={`grow grid ${layouts[lives_index][layout_index].grid || ''}`}>
        {lives && lives.length > 0 && lives.map((live, index) => {
            // noinspection JSUnresolvedVariable
            return (<div key={'live-' + live.id}
                         className={`bg-black ${layouts[lives_index][layout_index].items[index] || ''}`}>
                <Live index={index} channel={live.login}/>
            </div>)
        })}
    </div>)
}

export default Lives
