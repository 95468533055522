import CountMenu from "./CountMenu";
import StreamsMenu from "./StreamsMenu";
import LayoutMenu from "./LayoutMenu";
import {useEffect, useState} from "react";

const Menu = ({lives, setLives, layouts, layout, setLayout, chat, setChat, setCardData, setCardVisibility, setCardPosition}) => {
    const [status, setStatus] = useState(() => {
        return {timeout: 10, data: {}}
    })

    useEffect(() => {
        const timer = setTimeout(() => {
            fetch('https://failyv.ellezelin.com/streams_v2')
                .then((response) => response.json())
                .then((json) => {
                    setStatus({timeout: 60000, data: json})
                })
                .catch(console.error)
        }, status.timeout)

        return () => {
            clearTimeout(timer)
        }
    })

    return (<div className="bg-slate-800 flex">
        <CountMenu lives={lives} setLives={setLives} online={status.data.online || 0}/>
        <StreamsMenu lives={lives} setLives={setLives} chat={chat} setChat={setChat} data={status.data || {}}
                     setCardData={setCardData} setCardVisibility={setCardVisibility} setCardPosition={setCardPosition}/>
        <LayoutMenu lives={lives} layouts={layouts} layout={layout} setLayout={setLayout}/>
    </div>)
}

export default Menu
