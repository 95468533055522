import Stream from "./Stream";

const StreamsGroup = ({
                          organisations,
                          organisation,
                          streams,
                          lives,
                          setLives,
                          chat,
                          setChat,
                          setCardData,
                          setCardVisibility,
                          setCardPosition
                      }) => {
    const data = organisations[organisation] || {name: '', fullname: '', group_class: ''}
    const hasLive = lives.length > 0

    return (<div className={[
        hasLive && 'flex',
        !hasLive && 'contents',
        data.group_class || '',
    ].join(' ')}>
        {hasLive && data && data.name && data.name !== '' && (
            <div className="self-center w-5 text-center -rotate-90 origin-bottom-right select-none"
                 title={data.fullname}>{data.name}</div>)}
        {streams && streams.map((stream) => {
            return (<Stream key={'stream-' + stream.id} organisations={organisations} stream={stream} lives={lives}
                            setLives={setLives} chat={chat} setChat={setChat} setCardData={setCardData}
                            setCardVisibility={setCardVisibility} setCardPosition={setCardPosition}/>)
        })}
    </div>)
}

export default StreamsGroup
