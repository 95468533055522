import logo from "../failyvlogo.png";
import Chat from "./Chat";
import Lives from "./Lives";

const Player = ({layouts, layout, lives, chat}) => {
    return (<div className={`grow flex justify-center`}>
        {lives && lives.length > 0 && (<Lives layouts={layouts} layout={layout} lives={lives}/>)}
        {chat && chat !== '' && (<Chat chat={chat}/>)}
        {(!lives || lives.length <= 0) && (
            <img alt="FailyV" src={logo} className="grow-0 self-center" style={{width: '185px', height: '150px'}}/>)}
    </div>)
}

export default Player
