import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { faMessage } from '@fortawesome/free-regular-svg-icons'

const Stream = ({
                    organisations,
                    stream,
                    lives,
                    setLives,
                    chat,
                    setChat,
                    setCardData,
                    setCardVisibility,
                    setCardPosition
                }) => {
    const isLive = lives.some((live) => live.id === stream.id)
    const hasLive = lives.length > 0
    const defaultDisabledClass = 'bg-purple-900'
    const defaultEnabledClass = 'bg-purple-500'
    const organisation = organisations[stream.organisation] || {
        disabled_class: defaultDisabledClass, enabled_class: defaultEnabledClass, fullname: ''
    }

    //region Events
    const onMouseEnterEvent = (event) => {
        if (hasLive) {
            const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
            const rt = event.target.getBoundingClientRect()
            const ps = (rt.left + 320 + 50 > vw) ? (rt.right - 320) : rt.left
            setCardPosition(ps)
            setCardData({organisations: organisations, stream: stream})
            setCardVisibility(true)
        }
        event.stopPropagation()
    }
    const onMouseLeaveEvent = (event) => {
        setCardVisibility(false)
        event.stopPropagation()
    }

    const setAsLive = (event) => {
        setLives([stream])
        event.stopPropagation()
    }
    const addAsLive = (event) => {
        if (isLive) {
            setLives(lives.filter((live) => live.id !== stream.id))
        }
        if (!isLive) {
            setLives(lives.concat([stream]))
        }
        event.stopPropagation()
    }
    const setAsChat = (event) => {
        // noinspection JSUnresolvedVariable
        setChat(chat === stream.login ? '' : stream.login)
        event.stopPropagation()
    }
    //endregion

    // noinspection JSUnresolvedVariable
    return (<div onClick={setAsLive} onMouseEnter={onMouseEnterEvent} onMouseLeave={onMouseLeaveEvent}
                 className={[
                     'relative cursor-pointer p-0.5 rounded-md m-1 select-none transition-all text-white',
                     hasLive && 'w-15',
                     !hasLive && 'w-80 h-28 flex flex-col gap-1 justify-between',
                     !hasLive && 'bg-gradient-to-br from-purple-900 via-purple-900 to-transparent',
                     isLive && (organisation.enabled_class || defaultEnabledClass),
                     !isLive && (organisation.disabled_class || defaultDisabledClass),
                 ].join(' ')}>
        <div className="flex">
            <div style={{backgroundImage: `url(${stream.profile_image_url})`}}
                 className={[
                     'bg-no-repeat bg-cover bg-center bg-origin-border bg-slate-800',
                     'rounded-br-md rounded-tl-md shrink-0 transition-all',
                     hasLive && 'w-10 h-10',
                     !hasLive && 'w-14 h-14',
                 ].join(' ')}/>
            {hasLive && (
                <div className="flex flex-col grow pl-[2px]">
                    <div className={`grow align-middle`} onClick={addAsLive}>
                        <FontAwesomeIcon icon={faPlus}/>
                    </div>
                    <div className={`grow align-middle`} onClick={setAsChat}>
                        <FontAwesomeIcon icon={faMessage}/>
                    </div>
                </div>
            )}
            {!hasLive && (
                <div className="flex flex-col text-left pl-2">
                    <div className="text-lg leading-tight">{stream.display_name || ''}</div>
                    <div className="opacity-80 text-sm leading-tight max-h-9 overflow-hidden text-ellipsis">{stream.title || ''}</div>
                </div>
            )}
        </div>
        {hasLive && (
            <div className={`overflow-hidden text-ellipsis w-full`}>
                {stream.display_name}
            </div>
        )}
        {!hasLive && (
            <div className="flex justify-end">
                <div className="flex flex-col text-right pr-1">
                    <div className="text-white text-lg leading-tight">{stream.name || ''}</div>
                    {organisations && stream.organisation && organisations[stream.organisation] && (
                        <div
                            className="text-slate-200 text-sm leading-tight">
                            {organisations[stream.organisation].fullname}
                        </div>)}
                </div>
            </div>
        )}
    </div>)
}

export default Stream
